import debounce from './debounce.js';

$.fn.isInViewport = function () {
    const elementTop = $(this).offset().top;
    const elementBottom = elementTop + $(this).outerHeight();

    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + ($(window).height() - 100);

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

function checkVisibility() {
    $('.js-anim').each(function () {
        if ($(this).isInViewport() === true && !$(this).hasClass('.js-anim--active')) {
        if($(this).hasClass('js-anim--children')) {
            var animInterval = $(this).data('anim-interval') || 100;
            var animDelay = $(this).data('anim-delay') || 0;
            var that = this;
            var delay = setTimeout(function() {
            $(that).children().each(function(i) {
                var interval = animInterval * i;
                var target = this;
                var t = setTimeout(function() {
                $(target).addClass('js-anim--active');
                }, interval);
            });
            }, animDelay);
        }
        else {
            var animDelay = $(this).data('anim-delay') || 0;
            var that = this;
            var delay = setTimeout(function() {
            $(that).addClass('js-anim--active');
            }, animDelay);
        }
        }
    });
}

jQuery(function ($) {

    window.addEventListener('scroll', debounce(checkVisibility), { passive: true });

    checkVisibility();

    // prep js-anim--children
    $('.js-anim--children').each(function () {
        const animType = $(this).data('anim-type') || 'fadein';
        $(this).children().each(function(i) {
        $(this).addClass('js-anim--' + animType);
        });
    });
});

$(window).on('load', function () {
    checkVisibility();
});
