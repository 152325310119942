var mapOptions = {
    // How zoomed in you want the map to start at (always required)
    //zoom: 11,

    // How you would like to style the map.
    // This is where you would paste any style found on Snazzy Maps.
    styles: [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        }
    ]
};

// create global infowindow
infoWindow = new google.maps.InfoWindow;

(function($, document) {

    var js_maps = {

        /**
         * Our object cache
         */
        cache: function() {
            js_maps.els = {};

            // common elements
            js_maps.els.map_objects = [];
            js_maps.els.maps = $('.map');
            js_maps.els.document = $(document);
            js_maps.els.body = $('body');

        },

        /**
         * On doc ready
         */
        on_ready: function() {

            // on ready stuff here
            js_maps.cache();
            js_maps.setup_maps();

        },

        /**
         * Setup document
         */
        setup_maps: function() {

            js_maps.els.maps.each(function(){

                // create map
                js_maps.els.map_objects.push( js_maps.new_map( $(this) ) );

            });

            js_maps.els.document.on('resize', function(){

                $.each( js_maps.els.map_objects, function( i, map ) {
                    js_maps.center_map( map );
                });

            });

            js_maps.els.document.on('marker-clicked', function( event, el, $marker, counterpart){

                var $counterpart = $('#'+counterpart);

                if ( typeof scrollTo !== 'undefined' && $.isFunction(scrollTo) ) {

                    js_maps.els.body.scrollTo($counterpart, 250, {
                        interupt: true
                    });

                } else {

                    $counterpart[0].scrollIntoView();

                }

            });

        },

        /*
         * new_map
         *
         * This function will render a Google Map onto the selected jQuery element
         *
         * @type function
         * @date 8/11/2013
         * @since 4.3.0
         *
         * @param $el (jQuery element)
         * @return n/a
         */

        new_map: function( $el ) {

            // var
            var $markers = $el.find('.marker');

            // vars
            var args = {
                zoom : 18,
                zoomControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                center : new google.maps.LatLng(0, 0),
                mapTypeId : google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                styles: mapOptions.styles
            };

            // create map
            var map = new google.maps.Map( $el[0], args);

            // add a markers reference
            map.markers = [];

            // add markers
            $markers.each(function(){

                js_maps.add_marker( $(this), map );

            });

            // center map
            js_maps.center_map( map );

            // var zoomInControl = document.getElementById('map-zoom-in');
            // var zoomOutControl = document.getElementById('map-zoom-out');

            // google.maps.event.addDomListener(zoomInControl, 'click', function() {
            //   var currentZoom = map.getZoom();
            //   map.setZoom(currentZoom + 1);
            // });

            // google.maps.event.addDomListener(zoomOutControl, 'click', function() {
            //   var currentZoom = map.getZoom();
            //   map.setZoom(currentZoom - 1);
            // });

            // return
            return map;

        },

        /*
         * add_marker
         *
         * This function will add a marker to the selected Google Map
         *
         * @type function
         * @date 8/11/2013
         * @since 4.3.0
         *
         * @param $marker (jQuery element)
         * @param map (Google Map object)
         * @return n/a
         */

        add_marker: function( $marker, map ) {

            // var
            var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') ),
                counterpart = $marker.attr('data-counterpart');

            var icon = {
                url: $marker.attr('data-icon'),
                //labelOrigin: new google.maps.Point(35,105)
                scaledSize: new google.maps.Size(60,60)
            };

            if($marker.attr('data-size') == 'large') {
                //icon['scaledSize'] = new google.maps.Size(80,80);
            }

            // var labelText = $marker.find('.label').html();

            // var labelItem = {};

            // if(labelText.length) {
            //     labelItem = {
            //         text: labelText,
            //         color: '#53267c',
            //         fontWeight: 'bold',
            //         fontSize: '18px'
            //     }
            // }

            //console.log(labelText);

            // create marker
            var marker = new google.maps.Marker({
                position : latlng,
                map : map,
                title : '',
                icon : icon,
                animation:  google.maps.Animation.DROP,
                // label: labelItem
            });

            // add to array
            map.markers.push( marker );

            // if marker contains HTML, add it to an infoWindow
            if( $marker.html() ) {

                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function() {

                    infoWindow.setContent($marker.html());
                    infoWindow.open( map, marker );

                });

            } else if( typeof counterpart !== "undefined" ) {

                marker.addListener('click', function( el ) {
                    js_maps.els.document.trigger('marker-clicked', [el, $marker, counterpart]);
                });

            }

        },

        /*
         * center_map
         *
         * This function will center the map, showing all markers attached to this map
         *
         * @type function
         * @date 8/11/2013
         * @since 4.3.0
         *
         * @param map (Google Map object)
         * @return n/a
         */

        center_map: function( map ) {

            // vars
            var bounds = new google.maps.LatLngBounds();

            // loop through all markers and create bounds
            $.each( map.markers, function( i, marker ){

                var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

                bounds.extend( latlng );

            });

            // only 1 marker?
            if( map.markers.length == 1 ) {

                // set center of map
                map.setCenter( bounds.getCenter() );
                map.setZoom( 14 );

            } else {

                // fit to bounds
                map.fitBounds( bounds );

            }

        }

    };

    $(document).ready( js_maps.on_ready() );

}(jQuery, document));
