/* eslint prefer-arrow-callback:0 */
/* eslint global-require:0 */

/*
* JS external modules/libraries
*/

// import jquery. sort some namespaces out, as this will be largely handled by webpack.
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

// import modernizr build
require('./js/vendor/modernizr.js');

// slick carousel
const slick = require('slick-carousel');

// set up js-animation
require('./js/partials/js-anim.js');

jQuery(function ($) {

  require('./js/vendor/custom-select.jquery.js');
  require('./js/vendor/jquery.instagramFeed.min.js');
  require('./js/partials/responsive-svg.js');
  require('./js/partials/maps.js');

  // rellax library
  require('./js/partials/rellax.js');

  require('../components/header/index.js');
  require('../components/banner-carousel/index.js');
  require('../components/page-builder-faq-group/index.js');

});

$(window).on('load', function () {

});

// register service worker
// if ('serviceWorker' in navigator) {
//   // Use the window load event to keep the page load performant
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/serviceworker.js');
//   });
// }
