/*
* Menu handling
*/

// scroll handling

import debounce from '../../src/js/partials/debounce.js';

const header = document.querySelector('.header');

// Reads out the scroll position and stores it in the data attribute
// so we can use it in our stylesheets
const monitorHeaderScroll = () => {
    if (window.scrollY > 100) {
        header.classList.add('is-scrolled');
    } else {
        header.classList.remove('is-scrolled');
    }
    //document.documentElement.dataset.scroll = window.scrollY;
}

// Listen for new scroll events, here we debounce our `storeScroll` function
document.addEventListener('scroll', debounce(monitorHeaderScroll), { passive: true });

// Update scroll position for first time
monitorHeaderScroll();

// main menu toggle

$('.js-toggle-nav.header__toggle--nav').on('click', function() {
    $(this).toggleClass('is-active');
    $('.header__mobile-nav').toggleClass('is-active');
    $('body').toggleClass('mobile-menu--active');

    if (!$('body').hasClass('mobile-menu--active')) {
        removeNavAnimState();
    }

    // manually trigger scroll listeners, such as js-anim detection
    dispatchEvent(new CustomEvent('scroll'));
});

document.addEventListener('click', function(event) {

    if ($('body').hasClass('mobile-menu--active')) {
        var menuEl = document.getElementById('headerMobileMenu');
        var toggleEl = document.getElementById('toggleHeaderMenu');

        var clickInsideElement = menuEl.contains(event.target);
        var clickInsideToggle = toggleEl.contains(event.target);

        if (!clickInsideElement && !clickInsideToggle) {
            removeNavAnimState();
            closeMobileNav();
        }
    }

});

$(document).on('keyup', function(event) {
    if (event.key === "Escape") {
        if ($('body').hasClass('mobile-menu--active')) {
            removeNavAnimState();
            closeMobileNav();
        }
    }
});

function removeNavAnimState() {
    const mobileItems = document.getElementsByClassName('nav-mobile__item');
    [...mobileItems].forEach((element, index, array) => {
        element.classList.remove('js-anim--active');
    })
}

function closeMobileNav() {
    $('.js-toggle-nav.header__toggle--nav').removeClass('is-active');
    $('.header__mobile-nav').removeClass('is-active');
    $('body').removeClass('mobile-menu--active');
}
