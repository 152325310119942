import debounce from './debounce.js';

let Rellax = require('rellax');

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

const rellaxin = document.querySelectorAll(".rellax");

rellaxin.forEach(el => {

    const rellax = new Rellax(el, {
        relativeToWrapper: true,
        wrapper: el.parentElement,
        breakpoints: [600, 900, 1200]
    });

    window.addEventListener('scroll', debounce(() => { // fix to init
        rellax.refresh();
    }), { passive: true });
    // window.addEventListener("scroll", () => { // fix to init
    //   rellax.refresh();
    // });

});
