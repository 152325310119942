var slider = $('.banner-carousel__slider');

if (slider.length) {

    // $('.slick-dots li button').on('click', function(e) {
    //     console.log('dot doot');
    //     let activeItems = slider.find('.js-anim--active');
    //     activeItems.removeClass('js-anim--active');

    //     // manually trigger scroll listeners
    //     dispatchEvent(new CustomEvent('scroll'));
    // });

    slider.slick({
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 16000,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        adaptiveHeight: true,
        appendDots: '.banner-carousel__slider-nav'
    })
    .on('beforeChange', function(event, slick, currentSlide) {
        let activeItems = slider.find('.js-anim--active');
        activeItems.removeClass('js-anim--active');

        // manually trigger scroll listeners
        dispatchEvent(new CustomEvent('scroll'));
    });

}
